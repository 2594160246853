import React from 'react';
import Link from '../components/TransitionLink';
import backgroundImage from '../assets/images/background-404.jpg';
import { ButtonLink } from '../components/Link';
import Layout from '../containers/layout';
import SEO from '../components/seo';
import { useGaEvent } from '../hooks/hooks.ga-events';

const NotFoundPage = () => {
  const handleGaEventDonation = () => {
    useGaEvent('Donations', 'Donate Now');
  };

  return (
    <Layout pageType="404 Page">
      <SEO title="404: Not found" />
      <div className="page-404" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="page-404__content">
          <h1 className="heading-lg text-color-white page-404__title">Ops, pagina non trovata</h1>
          <p className="text-color-white paragraph page-404__description">
            Il contenuto che cerchi non esiste o è stato spostato. E se fosse capitato a un diritto?
            <br />
            Aiuta l’UNICEF Italia a difendere i diritti dei bambini in Italia e nel mondo
          </p>
          <ButtonLink
            label="dona ora"
            kind="primary"
            color="yellow"
            className="page-404__button"
            url="https://donazioni.unicef.it/"
            onClick={() => handleGaEventDonation()}
          />
          <p className="text-color-white paragraph page-404__link">
            Oppure ricomincia dalla
            {' '}
            <Link to="/" className="text-underline">homepage</Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
